import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7beb5f28"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "MakeKeyView"
};
const _hoisted_2 = {
  class: "FormView"
};
const _hoisted_3 = {
  style: {
    "margin": "10px 10px"
  }
};
const _hoisted_4 = {
  style: {
    "margin": "16px"
  }
};
const _hoisted_5 = {
  style: {
    "margin": "16px"
  }
};
import { onMounted, ref } from "vue";
import Common_Router from "@/utils/Common/Common_Router";
import Apis_Key from "@/apis/Apis_Key";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Apis_Agent from "@/apis/Apis_Agent";
import Apis_User from "@/apis/Apis_User";
export default {
  __name: 'MakeKey',
  setup(__props) {
    const PostData = ref({
      makeNum: null,
      //生成数量
      nickname: '',
      money: null,
      //卡密额度
      type: '1' //卡密类型[type]: 1=注册码 2=充值码
    });

    const onSubmit = () => {
      VantMsgLib.confirmSubmit('生成卡密', () => {
        let {
          makeNum,
          nickname,
          money,
          type
        } = PostData.value;
        Apis_Key.makeKey(makeNum, nickname, money, type);
      });
    };
    let AgentMoney = ref({
      reg: -1,
      rec: -1
    });
    onMounted(() => {
      Apis_Agent.getSelfMoney().then(res => {
        if (!Apis_User.checkResCode(res)) {
          return;
        }
        AgentMoney.value.reg = (res === null || res === void 0 ? void 0 : res.money_reg) || '???';
        AgentMoney.value.rec = (res === null || res === void 0 ? void 0 : res.money_rec) || '???';
      });
    });
    return (_ctx, _cache) => {
      const _component_HeaderNavBar = _resolveComponent("HeaderNavBar");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_radio = _resolveComponent("van-radio");
      const _component_van_radio_group = _resolveComponent("van-radio-group");
      const _component_van_tag = _resolveComponent("van-tag");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_HeaderNavBar, {
        title: "生成卡密"
      }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_form, {
        onSubmit: onSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, null, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            modelValue: PostData.value.makeNum,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => PostData.value.makeNum = $event),
            label: "数量",
            type: "digit",
            clearable: ""
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: PostData.value.money,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => PostData.value.money = $event),
            label: "额度",
            type: "digit",
            clearable: ""
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: PostData.value.nickname,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => PostData.value.nickname = $event),
            label: "备注",
            clearable: ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_van_field, {
          name: "radio",
          label: "类型"
        }, {
          input: _withCtx(() => [_createVNode(_component_van_radio_group, {
            modelValue: PostData.value.type,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => PostData.value.type = $event),
            direction: "horizontal"
          }, {
            default: _withCtx(() => [_createVNode(_component_van_radio, {
              name: "1"
            }, {
              default: _withCtx(() => [_createTextVNode("注册码")]),
              _: 1
            }), _createVNode(_component_van_radio, {
              name: "2"
            }, {
              default: _withCtx(() => [_createTextVNode("充值码")]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_tag, {
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("[余额]注册:" + _toDisplayString(_unref(AgentMoney).reg), 1)]),
          _: 1
        }), _createVNode(_component_van_tag, {
          style: {
            "margin-left": "5px"
          },
          type: "success"
        }, {
          default: _withCtx(() => [_createTextVNode("[余额]充值:" + _toDisplayString(_unref(AgentMoney).rec), 1)]),
          _: 1
        })]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => [_createTextVNode(" 提交 ")]),
          _: 1
        })]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "warning",
          onClick: _cache[4] || (_cache[4] = () => _unref(Common_Router).RefreshPage())
        }, {
          default: _withCtx(() => [_createTextVNode(" 刷新 ")]),
          _: 1
        })])]),
        _: 1
      })])]);
    };
  }
};